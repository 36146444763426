<template>
  <div id="addModify">
    <div class="headerDetail">
      <ds-header :title="headerTitle"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" labelAlign="right" :labelCol="{ style: 'width: 300px' }" :rules="rules">
      <div style="margin-top: 20px">
        <div class="content">
          <a-form-model-item label="所属项目：" prop="projectId" :span="24">
            <a-select class="indexClass" placeholder="请选择" :disabled="disabled" v-model="form.projectId">
              <a-select-option v-for="option in projectList" :key="option.id" :value="option.id">
                {{ option.project_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="皮肤名称：" prop="skinName" :span="24">
            <a-input :disabled="disabled" class="indexClass" :maxLength="10" placeholder="请输入皮肤名称"
              v-model.trim="form.skinName" />
          </a-form-model-item>
          <a-form-model-item label="我的icon：" prop="myIconList">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.myIconList" @change="handleChange"
              :remove="(file) => { return handleRemove(file, 1) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 1) }">
              <div v-if="this.form.myIconList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为60*60PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="常用封面图标：" prop="commonIcon">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.commonIcon" @change="handleCommonIcon"
              :remove="(file) => { return handleRemove(file, 2) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 1) }">
              <div v-if="this.form.commonIcon.length < 2">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为60*60PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="常用图标：" prop="commonCharts">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.commonCharts" @change="handleCommonCharts"
              :remove="(file) => { return handleRemove(file, 3) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 1) }">
              <div v-if="this.form.commonCharts.length < 4">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为60*60PX，最大不能超过1M</p>
          </a-form-model-item>
          <!--            <a-form-model-item label="小i通知(收起状态)：" prop="messageDefaultIcon">-->
          <!--                <a-upload-->
          <!--                  name="file"-->
          <!--                  :disabled="disabled"-->
          <!--                  list-type="picture-card"-->
          <!--                  :action="IMG_API + '/oss/files'"-->
          <!--                  class="avatar-uploader"-->
          <!--                  :file-list="form.messageDefaultIcon"-->
          <!--                  @change="handleDefaultIcon"-->
          <!--                  :remove=" (file) =>  { return handleRemove(file, 4)}"-->
          <!--                  @preview="handlePreview"-->
          <!--                  :before-upload=" (file) =>  { return beforeUpload(file, 2)}"-->
          <!--                >-->
          <!--                  <div v-if="this.form.messageDefaultIcon.length < 1">-->
          <!--                    <a-icon type="plus" />-->
          <!--                    <div class="ant-upload-text">Upload</div>-->
          <!--                  </div>-->
          <!--                </a-upload>-->
          <!--                <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为220*220PX，最大不能超过1M</p>-->
          <!--            </a-form-model-item>-->
          <!--            <a-form-model-item label="小i通知(展开状态)：" prop="messageUnfoldIcon">-->
          <!--                <a-upload-->
          <!--                  name="file"-->
          <!--                  :disabled="disabled"-->
          <!--                  list-type="picture-card"-->
          <!--                  :action="IMG_API + '/oss/files'"-->
          <!--                  class="avatar-uploader"-->
          <!--                  :file-list="form.messageUnfoldIcon"-->
          <!--                  @change="handleUnfoldIcon"-->
          <!--                  :remove=" (file) =>  { return handleRemove(file, 5)}"-->
          <!--                  @preview="handlePreview"-->
          <!--                  :before-upload=" (file) =>  { return beforeUpload(file, 3)}"-->
          <!--                >-->
          <!--                  <div v-if="this.form.messageUnfoldIcon.length < 1">-->
          <!--                    <a-icon type="plus" />-->
          <!--                    <div class="ant-upload-text">Upload</div>-->
          <!--                  </div>-->
          <!--                </a-upload>-->
          <!--                <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为220*220PX，最大不能超过1M</p>-->
          <!--            </a-form-model-item>-->
          <a-form-model-item label="底部导航（默认状态）：" prop="bottomNavigationDefault">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.bottomNavigationDefault" @change="handlebottomNavigationDefault"
              :remove="(file) => { return handleRemove(file, 6) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 4) }">
              <div v-if="this.form.bottomNavigationDefault.length < 2">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为120*80PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="小程序底部导航（默认状态）：" prop="bottomNavigationMiniprogramDefault">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.bottomNavigationMiniprogramDefault"
              @change="handlebottomNavigationMiniprogramDefault" :remove="(file) => { return handleRemove(file, 10) }"
              @preview="handlePreview" :before-upload="(file) => { return beforeUpload(file, 10) }">
              <div v-if="this.form.bottomNavigationMiniprogramDefault.length < 4">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为81*81PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="底部导航（选中状态）：" prop="bottomNavigationUnfold">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.bottomNavigationUnfold" @change="handlebottomNavigationUnfold"
              :remove="(file) => { return handleRemove(file, 7) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 4) }">
              <div v-if="this.form.bottomNavigationUnfold.length < 2">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为120*80PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="小程序底部导航（选中状态）：" prop="bottomNavigationMiniprogramUnfold">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.bottomNavigationMiniprogramUnfold"
              @change="handlebottomNavigationMiniprogramUnfold" :remove="(file) => { return handleRemove(file, 11) }"
              @preview="handlePreview" :before-upload="(file) => { return beforeUpload(file, 11) }">
              <div v-if="this.form.bottomNavigationMiniprogramUnfold.length < 4">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为81*81PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="底部导航-中间页图标（icon图）：" prop="codeIconList">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.codeIconList" @change="handleChangeCodeIcon"
              :remove="(file) => { return handleRemove(file, 8) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 5) }">
              <div v-if="this.form.codeIconList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为80*80PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="底部导航-中间页图标（背景图）：" prop="codeBackgroundImgList">
            <a-upload name="file" :disabled="disabled" list-type="picture-card" :action="IMG_API + '/oss/files'"
              class="avatar-uploader" :file-list="form.codeBackgroundImgList" @change="handleChangeCodeBackground"
              :remove="(file) => { return handleRemove(file, 9) }" @preview="handlePreview"
              :before-upload="(file) => { return beforeUpload(file, 6) }">
              <div v-if="this.form.codeBackgroundImgList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">图片类型支持jpg/png/GIF类型，尺寸为250*200PX，最大不能超过1M</p>
          </a-form-model-item>
          <a-form-model-item label="页面名称：" :span="24">
            <div style="display: flex;flex-direction: column;">
              <a-input :disabled="disabled" class="indexClass" placeholder="首页名称" :maxLength="3"
                v-model.trim="form.leftPageName" />
              <a-input style="margin-top: 20px;" :disabled="disabled" class="indexClass" placeholder="中间页名称"
                :maxLength="3" v-model.trim="form.centerPageName" />
              <a-input style="margin-top: 20px;" :disabled="disabled" class="indexClass" placeholder="i友圈页名称"
                :maxLength="3" v-model.trim="form.rightPageName" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="状态：" prop="status">
            <a-radio-group name="radioGroup" v-model="form.status" :disabled="disabled">
              <a-radio :value="1"> 启用 </a-radio>
              <a-radio :value="0"> 禁用 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="皮肤设置为默认：" prop="isDefault">
            <a-radio-group name="radioGroup" v-model="form.isDefault" :disabled="disabled">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.isDefault === 0" label="生效时间" prop="effectiveTime">
            <a-range-picker :disabled="disabled" v-model="form.effectiveTime" show-time />
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <div class="footer">
      <a-button key="submit" style="margin-right: 30px" type="primary" v-if="$route.query.type != 'view'"
        @click="onSubmit">
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import {
  projectList,
  addSkin,
  infomationSkin,
  updateSkin
} from "@/api/skinManage";
import moment from 'moment/moment';
import {mapState} from "vuex";
export default {
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projectList;
      },
    }),
  },
  data() {
    return {
      rules: {
        projectId: [{ required: true, message: "请选择所属项目", trigger: "blur" }],
        skinName: [{ required: true, message: "请输入皮肤名称", trigger: "blur" }],
        myIconList: [{ required: true, message: '请上传我的icon', trigger: 'change' }],
        commonIcon: [{ required: true, message: '请上传常用封面图片', trigger: 'change' }],
        commonCharts: [{ required: true, message: '请上传常用图片', trigger: 'change' }],
        // messageDefaultIcon: [{ required: true, message: '请上传小i通知(收起状态)', trigger: 'change' }],
        // messageUnfoldIcon: [{ required: true, message: '请上传小i通知(展开状态)', trigger: 'change' }],
        bottomNavigationDefault: [{ required: true, message: '请上传底部导航(默认状态)', trigger: 'change' }],
        bottomNavigationUnfold: [{ required: true, message: '请上传底部导航(选中状态)', trigger: 'change' }],
        codeIconList: [{ required: true, message: '请上传底部导航-中间页图标（icon图）', trigger: 'change' }],
        codeBackgroundImgList: [{ required: true, message: '请上传底部导航-中间页图标（背景图）', trigger: 'change' }],
        status: [{ required: true, message: "", trigger: "change" }],
        isDefault: [{ required: true, message: "", trigger: "change" }],
        effectiveTime: [{ required: true, message: '请选择生效时间', trigger: 'change' }],
      },
      headerTitle: "新建皮肤",
      IMG_API: IMG_API,
      projectName: [], // 项目列表
      form: {
        projectId: undefined, // 项目ID
        skinName: '', // 皮肤名称
        myIconList: [], // 我的icon
        myIcon: '', // 我的icon
        commonIcon: [], // 常用封面图标
        // commonIconDefault: "", // 常用封面图标默认状态icon
        // commonIconSelected: "", // 常用封面图标选中状态icon
        commonCharts: [], // 常用图标
        // richScanIcon: "", // 扫一扫icon
        // topUpIcon: "", // 充值icon
        // paymentCodeIcon: "", // 付款码icon
        // passImpededCodeIcon: "", // 畅行码icon
        // messageDefaultIcon: [], // 小i通知收起
        // messageDefaultIcon: "", // 小i收起状态icon
        // messageUnfoldIcon: [], // 小i通知展开
        // messageUnfoldIcon: "", // 小i展开状态icon
        bottomNavigationDefault: [], // 底部导航默认
        // homePageDefaultIcon: "", // 首页默认图标
        // iyouCircleDefaultIcon: "", // i友圈默认图标
        bottomNavigationUnfold: [], // 底部导航选中
        // homePageSelectedIcon: "", // 首页选中图标
        // iyouCircleSelectedIcon: "", // i友圈选中图标
        // codeIcon: '', // 底部导航-中间页图标
        codeIconList: [], // 底部导航-中间页图标
        // codeBackgroundImg: '', // 底部导航-中间页图标
        codeBackgroundImgList: [], // 底部导航-中间页图标背景图
        leftPageName: '',
        centerPageName: '',
        rightPageName: '',
        status: 0, // 状态
        isDefault: 0, // 默认
        effectiveStartTime: "", // 生效开始时间
        effectiveEndTime: "", // 生效结束时间
        effectiveTime: [], // 生效时间
        bottomNavigationMiniprogramDefault: [], // 小程序底部导航默认
        bottomNavigationMiniprogramUnfold: [], // 小程序底部导航选中
      },
      previewVisible: false, // 预览弹窗开关
      previewImage: "", // 预览图片地址
      checkSize: false, // 校验图片大小
      checkwh: false, //校验图片宽高
      checkFormat: false, //校验格式
      disabled: false
    };
  },
  created() {
    // this.projectList();
    if (this.$route.query.id) {
      this.getInfomation();
    }
    if (this.$route.query.type == 'update') {
      this.headerTitle = "修改皮肤";
    }
    if (this.$route.query.type == 'view') {
      this.disabled = true;
      this.headerTitle = "查看皮肤";
    }
  },
  methods: {
    // 详情
    async getInfomation() {
      let data = {};
      data.id = this.$route.query.id;
      let res = await infomationSkin(data);
      this.form.projectId = res.data.projectId;
      this.form.skinName = res.data.skinName;
      this.form.status = res.data.status;
      this.form.isDefault = res.data.isDefault;
      this.form.leftPageName = res.data.leftPageName;
      this.form.centerPageName = res.data.centerPageName;
      this.form.rightPageName = res.data.rightPageName;
      if (res.data.effectiveStartTime) {
        this.form.effectiveTime = [moment(res.data.effectiveStartTime).format('YYYY-MM-DD HH:mm:ss'), moment(res.data.effectiveEndTime).format('YYYY-MM-DD HH:mm:ss')]
      }
      this.form.myIconList = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.myIcon,
      }];
      this.form.commonIcon = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.commonIconDefault,
      }, {
        uid: "2",
        name: "image.png",
        status: "done",
        url: res.data.commonIconSelected,
      }]
      this.form.commonCharts = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.richScanIcon,
      }, {
        uid: "2",
        name: "image.png",
        status: "done",
        url: res.data.topUpIcon,
      }, {
        uid: "3",
        name: "image.png",
        status: "done",
        url: res.data.paymentCodeIcon,
      }, {
        uid: "4",
        name: "image.png",
        status: "done",
        url: res.data.passImpededCodeIcon,
      }]
      // this.form.messageDefaultIcon = [{
      //   uid: "1",
      //   name: "image.png",
      //   status: "done",
      //   url: res.data.messageDefaultIcon,
      // }]
      // this.form.messageUnfoldIcon = [{
      //   uid: "1",
      //   name: "image.png",
      //   status: "done",
      //   url: res.data.messageUnfoldIcon,
      // }]
      this.form.bottomNavigationDefault = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.homePageDefaultIcon,
      }, {
        uid: "2",
        name: "image.png",
        status: "done",
        url: res.data.iyouCircleDefaultIcon,
      }]
      this.form.bottomNavigationUnfold = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.homePageSelectedIcon,
      }, {
        uid: "2",
        name: "image.png",
        status: "done",
        url: res.data.iyouCircleSelectedIcon,
      }]
      this.form.codeIconList = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.codeIcon,
      }];
      this.form.codeBackgroundImgList = [{
        uid: "1",
        name: "image.png",
        status: "done",
        url: res.data.codeBackgroundImg,
      }];
      if (res.data.homePageDefaultIconApple && res.data.codeDefaultIconApple && res.data.iyouCircleDefaultIconApple && res.data.myDefaultIconApple) {
        this.form.bottomNavigationMiniprogramDefault = [{
          uid: "1",
          name: "image.png",
          status: "done",
          url: res.data.homePageDefaultIconApple,
        }, {
          uid: "2",
          name: "image.png",
          status: "done",
          url: res.data.codeDefaultIconApple,
        }, {
          uid: "3",
          name: "image.png",
          status: "done",
          url: res.data.iyouCircleDefaultIconApple,
        }, {
          uid: "4",
          name: "image.png",
          status: "done",
          url: res.data.myDefaultIconApple,
        }];
      }
      if (res.data.homePageSelectedIconApple && res.data.codeSelectedIconApple && res.data.iyouCircleSelectedIconApple && res.data.mySelectedIconApple) {
        this.form.bottomNavigationMiniprogramUnfold = [{
          uid: "1",
          name: "image.png",
          status: "done",
          url: res.data.homePageSelectedIconApple,
        }, {
          uid: "2",
          name: "image.png",
          status: "done",
          url: res.data.codeSelectedIconApple,
        }, {
          uid: "3",
          name: "image.png",
          status: "done",
          url: res.data.iyouCircleSelectedIconApple,
        }, {
          uid: "4",
          name: "image.png",
          status: "done",
          url: res.data.mySelectedIconApple,
        }]
      }
    },
    // 移除
    handleRemove(file, e) {
      if (this.$route.query.type == 'update') {
        if (e == 1) {
          this.form.myIconList = [];
        } else if (e == 2) {
          let index = this.form.commonIcon.findIndex((item) => {
            return item.uid === file.uid
          })
          this.form.commonIcon.splice(index, 1)
        } else if (e == 3) {
          let index = this.form.commonCharts.findIndex((item) => {
            return item.uid === file.uid
          })
          this.form.commonCharts.splice(index, 1)
        } else if (e == 4) {
          this.form.messageDefaultIcon = [];
        } else if (e == 5) {
          this.form.messageUnfoldIcon = [];
        } else if (e == 6) {
          let index = this.form.bottomNavigationDefault.findIndex((item) => {
            return item.uid === file.uid
          })
          this.form.bottomNavigationDefault.splice(index, 1)
        } else if (e == 7) {
          let index = this.form.bottomNavigationUnfold.findIndex((item) => {
            return item.uid === file.uid
          })
          this.form.bottomNavigationUnfold.splice(index, 1)
        } else if (e == 8) {
          this.form.codeIconList = [];
        } else if (e == 9) {
          this.form.codeBackgroundImgList = [];
        } else if (e == 10) {
          let index = this.form.bottomNavigationMiniprogramDefault.findIndex((item) => {
            return item.uid === file.uid
          })
          this.form.bottomNavigationMiniprogramDefault.splice(index, 1)
        } else if (e == 11) {
          let index = this.form.bottomNavigationMiniprogramUnfold.findIndex((item) => {
            return item.uid === file.uid
          })
          this.form.bottomNavigationMiniprogramUnfold.splice(index, 1)
        }
      }
    },

    // 提交
    async onSubmit() {
      if (this.form.commonIcon.length < 2) {
        this.$message.error('请上传两张常用封面图标');
        return;
      }
      if (this.form.commonCharts.length < 4) {
        this.$message.error('请上传四张常用图标');
        return;
      }
      if (this.form.bottomNavigationDefault.length < 2) {
        this.$message.error('请上传两张底部导航默认');
        return;
      }
      if (this.form.bottomNavigationUnfold.length < 2) {
        this.$message.error('请上传两张底部导航选中');
        return;
      }
      if (this.form.bottomNavigationMiniprogramDefault.length && this.form.bottomNavigationMiniprogramDefault.length < 4) {
        this.$message.error('请上传四张小程序底部导航默认');
        return;
      }
      if (this.form.bottomNavigationMiniprogramUnfold.length && this.form.bottomNavigationMiniprogramUnfold.length < 4) {
        this.$message.error('请上传四张小程序底部导航选中');
        return;
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          var params = {};
          params.myIcon = this.form.myIconList[0].response ? this.form.myIconList[0].response.redirect_uri : this.form.myIconList[0].url;
          params.commonIconDefault = this.form.commonIcon[0].response ? this.form.commonIcon[0].response.redirect_uri : this.form.commonIcon[0].url;
          params.commonIconSelected = this.form.commonIcon[1].response ? this.form.commonIcon[1].response.redirect_uri : this.form.commonIcon[1].url;
          params.richScanIcon = this.form.commonCharts[0].response ? this.form.commonCharts[0].response.redirect_uri : this.form.commonCharts[0].url;
          params.topUpIcon = this.form.commonCharts[1].response ? this.form.commonCharts[1].response.redirect_uri : this.form.commonCharts[1].url;
          params.paymentCodeIcon = this.form.commonCharts[2].response ? this.form.commonCharts[2].response.redirect_uri : this.form.commonCharts[2].url;
          params.passImpededCodeIcon = this.form.commonCharts[3].response ? this.form.commonCharts[3].response.redirect_uri : this.form.commonCharts[3].url;
          // params.messageDefaultIcon = this.form.messageDefaultIcon[0].response ? this.form.messageDefaultIcon[0].response.redirect_uri : this.form.messageDefaultIcon[0].url;
          // params.messageUnfoldIcon = this.form.messageUnfoldIcon[0].response ? this.form.messageUnfoldIcon[0].response.redirect_uri : this.form.messageUnfoldIcon[0].url;
          params.homePageDefaultIcon = this.form.bottomNavigationDefault[0].response ? this.form.bottomNavigationDefault[0].response.redirect_uri : this.form.bottomNavigationDefault[0].url;
          params.iyouCircleDefaultIcon = this.form.bottomNavigationDefault[1].response ? this.form.bottomNavigationDefault[1].response.redirect_uri : this.form.bottomNavigationDefault[1].url;
          params.homePageSelectedIcon = this.form.bottomNavigationUnfold[0].response ? this.form.bottomNavigationUnfold[0].response.redirect_uri : this.form.bottomNavigationUnfold[0].url;
          params.iyouCircleSelectedIcon = this.form.bottomNavigationUnfold[1].response ? this.form.bottomNavigationUnfold[1].response.redirect_uri : this.form.bottomNavigationUnfold[1].url;
          params.codeIcon = this.form.codeIconList[0].response ? this.form.codeIconList[0].response.redirect_uri : this.form.codeIconList[0].url;
          params.codeBackgroundImg = this.form.codeBackgroundImgList[0].response ? this.form.codeBackgroundImgList[0].response.redirect_uri : this.form.codeBackgroundImgList[0].url;

          if (this.form.bottomNavigationMiniprogramDefault.length === 4) {
            params.homePageDefaultIconApple = this.form.bottomNavigationMiniprogramDefault[0].response ? this.form.bottomNavigationMiniprogramDefault[0].response.redirect_uri : this.form.bottomNavigationMiniprogramDefault[0].url;
            params.codeDefaultIconApple = this.form.bottomNavigationMiniprogramDefault[1].response ? this.form.bottomNavigationMiniprogramDefault[1].response.redirect_uri : this.form.bottomNavigationMiniprogramDefault[1].url;
            params.iyouCircleDefaultIconApple = this.form.bottomNavigationMiniprogramDefault[2].response ? this.form.bottomNavigationMiniprogramDefault[2].response.redirect_uri : this.form.bottomNavigationMiniprogramDefault[2].url;
            params.myDefaultIconApple = this.form.bottomNavigationMiniprogramDefault[3].response ? this.form.bottomNavigationMiniprogramDefault[3].response.redirect_uri : this.form.bottomNavigationMiniprogramDefault[3].url;
          }
          if (this.form.bottomNavigationMiniprogramUnfold.length === 4) {
            params.homePageSelectedIconApple = this.form.bottomNavigationMiniprogramUnfold[0].response ? this.form.bottomNavigationMiniprogramUnfold[0].response.redirect_uri : this.form.bottomNavigationMiniprogramUnfold[0].url;
            params.codeSelectedIconApple = this.form.bottomNavigationMiniprogramUnfold[1].response ? this.form.bottomNavigationMiniprogramUnfold[1].response.redirect_uri : this.form.bottomNavigationMiniprogramUnfold[1].url;
            params.iyouCircleSelectedIconApple = this.form.bottomNavigationMiniprogramUnfold[2].response ? this.form.bottomNavigationMiniprogramUnfold[2].response.redirect_uri : this.form.bottomNavigationMiniprogramUnfold[2].url;
            params.mySelectedIconApple = this.form.bottomNavigationMiniprogramUnfold[3].response ? this.form.bottomNavigationMiniprogramUnfold[3].response.redirect_uri : this.form.bottomNavigationMiniprogramUnfold[3].url;
          }

          // params.myIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.commonIconDefault = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.commonIconSelected = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.richScanIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.topUpIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.paymentCodeIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.passImpededCodeIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.messageDefaultIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.messageUnfoldIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.homePageDefaultIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.iyouCircleDefaultIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.homePageSelectedIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.iyouCircleSelectedIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.codeIcon = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          // params.codeBackgroundImg = "https://oss-workplace-prod.innoecos.cn/picture/058f057edb800000_%E6%88%AA%E5%B1%8F2022-10-08%20%E4%B8%8B%E5%8D%884.52.30%201_24x24.png"
          if (this.form.isDefault == 0) {
            params.effectiveStartTime = moment(this.form.effectiveTime[0]).format('YYYY-MM-DD HH:mm:ss');
            params.effectiveEndTime = moment(this.form.effectiveTime[1]).format('YYYY-MM-DD HH:mm:ss');
          }
          params.leftPageName = this.form.leftPageName;
          params.centerPageName = this.form.centerPageName;
          params.rightPageName = this.form.rightPageName;
          params.projectId = this.form.projectId;
          params.skinName = this.form.skinName;
          params.status = this.form.status;
          params.isDefault = this.form.isDefault;

          if (this.$route.query.id) {
            params.id = this.$route.query.id;
            const res = await updateSkin(params);
            if (res.code === "200") {
              this.$message.success("修改成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            let res = await addSkin(params);
            if (res.code === "200") {
              this.$message.success("提交成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          }

          // console.log(params);
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },

    // 项目下拉框
    // async projectList() {
    //   const res = await projectList();
    //   this.projectName = res.data.items;
    // },

    // 底部导航选中状态
    handlebottomNavigationUnfold({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.bottomNavigationUnfold = fileList;
      }
    },

    // 底部导航默认状态
    handlebottomNavigationDefault({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.bottomNavigationDefault = fileList;
      }
    },

    // 小程序底部导航默认状态
    handlebottomNavigationMiniprogramDefault({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.bottomNavigationMiniprogramDefault = fileList;
      }
    },

    // 底部导航选中状态
    handlebottomNavigationMiniprogramUnfold({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.bottomNavigationMiniprogramUnfold = fileList;
      }
    },

    // 小i通知展开
    handleUnfoldIcon({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.messageUnfoldIcon = fileList;
      }
    },

    // 小i通知收起
    handleDefaultIcon({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.messageDefaultIcon = fileList;
      }
    },

    // 常用图标
    handleCommonCharts({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.commonCharts = fileList;
      }
    },

    // 常用封面图标
    handleCommonIcon({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.commonIcon = fileList;
      }
    },

    // 我的icon图片上传
    handleChange({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.myIconList = fileList;
      }
    },

    // 底部导航-中间页图标
    handleChangeCodeIcon({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.codeIconList = fileList;
      }
    },

    // 底部导航-中间页图标
    handleChangeCodeBackground({ fileList }) {
      if (this.checkFormat && this.checkSize && this.checkwh) {
        this.form.codeBackgroundImgList = fileList;
      }
    },

    // 预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    // 弹窗图片预览关闭弹窗
    previewHandleCancel() {
      this.previewVisible = false;
    },

    // 图片限制
    beforeUpload(file, e) {
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg" || type === "gif" || type === "jpeg";
      this.checkFormat = isJPG;
      const isLt2M = file.size / 1024 / 1024 < 1;
      this.checkSize = isLt2M;
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png、gif格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过1MB!");
        return false;
      }
      let width;
      let height;
      if (e == 1) {
        width = 60;
        height = 60;
      } else if (e == 2) {
        width = 220;
        height = 220;
      } else if (e == 3) {
        width = 220;
        height = 220;
      } else if (e == 4) {
        width = 120;
        height = 80;
      } else if (e == 5) {
        width = 80;
        height = 80;
      } else if (e == 6) {
        width = 250;
        height = 200;
      } else if (e == 10 || e == 11) {
        width = 81;
        height = 81;
      }
      const checkwh = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      this.checkwh = checkwh;
      return isJPG && isLt2M && checkwh;
    },
  },
};
</script>

<style scoped lang="scss">
.indexClass {
  width: 330px;
}

.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

::v-deep .ant-form-item {
  display: flex;
  // margin-bottom: 0;
}

::v-deep .avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  /* margin-left:24px; */
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

::v-deep .ant-checkbox-group {
  width: 100%;

  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

::v-deep #editer {
  tr {
    height: 28.5px;
  }
}

::v-deep .ant-table-thead {
  th {
    &:before {
      content: "*";
      color: red;
    }
  }

  th:nth-child(4) {
    &:before {
      content: "";
    }
  }
}

::v-deep .ant-select-dropdown {
  z-index: 99999;
}

::v-deep .ant-modal-close-x {
  margin: -13px -13px 0 0;
}

.upImg {
  width: 109px;
  height: 109px;
}

.informationBox>div {
  margin-top: 40px;
}

.informationBox>div:nth-child(1) {
  margin-top: 0px;
}

.tip {
  font-size: 12px;
  line-height: 20px;
}
</style>
